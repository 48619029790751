import { chAggregateCombinedLocale } from "./aggregatesAndCombined";
import { chAuthLocale } from "./auth";
import { chBtnActionsLocale } from "./btnActions";
import { chCheckPaymentLocale } from "./checkPayment";
import { chErrorInputsLocale } from "./errorMessages";
import { chFinishOrder } from "./finishOrder";
import { chHomeLocale } from "./home";
import { chInputsLocale } from "./inputs";
import { chLanguageLocale } from "./languages";
import { chMenuItemLocale } from "./menuItemAppBar";
import { chOrderLocale } from "./order";
import { chOrdersLocale } from "./orders";
import { chReservationLocale } from "./reservation";
import { chTipLocale } from "./tip";
import { chUserAddressLocale } from "./addresses";
import { chUserSettingsLocale } from "./userSettings";
import { chLegalDocuments } from "./legalDocuments";

export default {
  ...chAggregateCombinedLocale,
  ...chAuthLocale,
  ...chBtnActionsLocale,
  ...chCheckPaymentLocale,
  ...chErrorInputsLocale,
  ...chFinishOrder,
  ...chHomeLocale,
  ...chInputsLocale,
  ...chLanguageLocale,
  ...chMenuItemLocale,
  ...chOrderLocale,
  ...chOrdersLocale,
  ...chReservationLocale,
  ...chTipLocale,
  ...chUserAddressLocale,
  ...chUserSettingsLocale,
  ...chLegalDocuments,
  lang: "语言",
  add: "添加",
  products: "产品",
  productsAdded: "已添加的产品",
  productAdded: "已添加的产品",
  waiterCalled: "已呼叫服务员，请等待服务",
  problem: "哎呀！出了点问题。请重试",
  notFound: "哎呀！找不到页面",
  notFoundText: "哎呀！似乎这个页面不存在",
  menu: "菜单",
  getMeOut: "带我离开这里",
  productsNotFound: "找不到产品！",
  store: "商店",
  myRequest: "订单",
  addNote: "是否要在订单中添加注释？",
  makeRequest: "完成订单",
  emptyCart: "清空购物车",
  cartEmpty: "购物车为空！",
  addProducts: "添加产品",
  successRequest: "订单已成功创建",
  scanQrAgain: "您需要重新扫描QR码",
  thankYou: "非常感谢！",
  requestMade: "您的订单已成功下达。",
  returnToMenu: "返回菜单",
  welcome: "欢迎！",
  scanQr: "浏览菜单选项或扫描桌上的QR码查看菜单或下订单。",
  shoppingCart: "购物车",
  requestAdded: "您的订单已完成",
  welcomeScan: "欢迎，扫描QR码。",
  loading: "加载中...",
  callWaiter: "呼叫服务员",
  languageNotAdded: "抱歉，系统尚未翻译成该语言",
  legalConcernTitle: "Legal Concern",
  cookiePolicyTitle: "Cookie Policy",
  privacyPolicyTitle: "Privacy Policy",
};
